<template>
  <div class="main">
    <div class="main__title mb-5">
      <span class="text-h5 dark-cyanBlue font-weight-semi-bold base-family">Available phone numbers</span>
    </div>
    <div class="main__content d-flex">
      <div class="main__content__left mr-5">
        <div class="bg-white pa-4 rounded">
          <div class="main__content__left__types">
            <span class="text-h6 dark-cyanBlue font-weight-regular base-family">Choose number type</span>
            <v-radio-group v-model="numberType" row>
              <v-radio label="Longcode" value="long_code" />
              <v-radio label="Toll Free" value="toll_free" />
            </v-radio-group>
          </div>
          <div class="d-flex">
            <v-col v-if="numberType === 'long_code'" class="pa-0 mr-4">
              <div class="main__content__left__dropdown">
                <span class="text-subtitle-1 dark-cyanBlue base-family">State</span>
                <v-select v-model="state" :items="stateOptions" item-text="label" item-value="value"
                  label="Choose state" dense solo @change="changeState" />
              </div>
            </v-col>
            <v-col class="pa-0" cols="6">
              <div class="main__content__left__dropdown">
                <span class="text-subtitle-1 dark-cyanBlue base-family">Area code</span>
                <v-select v-model="areaCode" :items="areaCodeOptions" :loading="codeLoading"
                  :disabled="!state || codeLoading" dense item-text="label" item-value="value" label="Choose code" solo
                  @change="getNumbersList" />
              </div>
            </v-col>
          </div>
        </div>
        <div class="main__content__left__table bg-white mt-5">
          <v-data-table
            v-model="selectedNumbers"
            :headers="headers"
            :items="items"
            :single-select="singleSelect"
            :loading="numbersLoading"
            item-key="tn"
            show-select
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:header.count="{ header }">
              {{ selectedNumbers.length + ' ' + header.text }}
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="main__content__right bg-white pa-4 rounded">
        <checkout
          :selected-numbers="selectedNumbers"
          :total="totalAmount"
          :stripe-loading="paymentLoading"
          @changeDurationType="changeDurationType"
          @removeNumber="removeNumber"
          @onStripe="buyNumber('stripe')"
        />
      </div>
    </div>
    <vue-snotify />
  </div>
</template>

<script>
import Checkout from '../components/Checkout.vue'

const delay = ms => new Promise((resolve) => setTimeout(resolve, ms))
export default {
  name: 'BuyNewNumber',
  components: {
    Checkout
  },
  data () {
    return {
      country: 'US',
      state: 'NY',
      stateOptions: [],
      areaCode: 838,
      areaCodeOptions: [],
      codeLoading: false,
      numberType: 'long_code',
      selectedNumbers: [],
      numbersLoading: false,
      headers: [
        {
          text: 'All numbers',
          align: 'start',
          value: 'tn'
        },
        {
          text: 'phone numbers',
          value: 'count',
          align: 'end',
          sortable: false
        }
      ],
      items: [
        {
          number: '123123123123',
          id: '1'
        },
        {
          number: '123123123123',
          id: '2'
        },
        {
          number: '123123123123',
          id: '3'
        },
        {
          number: '123123123123',
          id: '4'
        },
        {
          number: '123123123123',
          id: '5'
        }
      ],
      singleSelect: false,
      features: ['sms', 'voice'],
      durationType: 'month',
      totalAmount: 0,
      paymentLoading: false
    }
  },
  created () {
    this.getStatesHandler()
    this.getAreaCodesHandler(this.state)

    this.getNumbersList()
  },
  watch: {
    selectedNumbers (val) {
      console.log(val)
      this.getTotalAmout()
    }
  },
  methods: {
    async getStatesHandler () {
      try {
        const { data } = await this.$store.dispatch('getStates')

        this.stateOptions = data.payload.map(state => ({
          value: state,
          label: state
        }))

        console.log(data, 'states')
      } catch (e) {
        throw new Error(e)
      }
    },
    async getAreaCodesHandler (state) {
      try {
        this.areaCodeOptions = []
        this.codeLoading = true

        const { data } = await this.$store.dispatch('getAreaCodes', state)

        data.forEach((item) => {
          item.npa.forEach(code => {
            if (!this.areaCodeOptions.includes(code)) {
              this.areaCodeOptions.push(code)
            }
          })
        })

        this.areaCodeOptions = this.areaCodeOptions.map(code => ({
          value: code,
          label: code
        }))
      } catch (e) {
        throw new Error(e)
      } finally {
        this.codeLoading = false
      }
    },
    async getNumbersList () {
      try {
        this.numbersLoading = true

        const payload = {
          type: this.numberType,
          country: this.country,
          state: this.state,
          npa: this.areaCode,
          features: this.features
        }

        const data = await this.$store.dispatch('fetchNumbersList', payload)

        if (data.success) {
          this.items = data.payload.data
        }
        console.log(data, 'fetch numbers response')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.numbersLoading = false
      }
    },
    changeState () {
      this.getAreaCodesHandler(this.state)

      this.getNumbersList()
    },
    removeNumber (tn) {
      this.selectedNumbers = this.selectedNumbers.filter(item => item.tn !== tn)
    },
    changeDurationType (val) {
      this.durationType = val

      this.getTotalAmout()
    },
    async getTotalAmout () {
      try {
        console.log(this.durationType)
        const payload = {
          durationUnit: this.durationType,
          numbers: this.selectedNumbers.map(item => ({
            tn: item.tn,
            numberType: this.numberType,
            features: this.features
          }))
        }

        console.log(payload)

        const data = await this.$store.dispatch('fetchTotalAmount', payload)

        this.totalAmount = data.amount
        console.log(data, 'total amount ')
      } catch (e) {
        throw new Error(e)
      }
    },
    async buyNumber (type) {
      try {
        if (!this.selectedNumbers.length) {
          this.$snotify.notifications = []
          this.$snotify.error('Please select the number')

          return
        }

        this.paymentLoading = true

        const numbers = this.selectedNumbers.map(item => ({
          tn: item.tn,
          features: this.features,
          autorenew: true
        }))

        const { status, data } = await this.$store.dispatch('orderNewNumber', numbers)
        console.log(data, 'data order order')

        let response = null

        if (status === 201 || status === 200) {
          console.log('m,tav arnel;u')
          response = await this.waitForPaymentId(data.orderUuid, type)
        }
        console.log(response, 'data buy bugy buy buyb')

        if (response) {
          window.location.replace(response.data.paymentUrl)
        }
      } catch (e) {
        console.log(e, 'errror')
      } finally {
        this.paymentLoading = false
      }
    },
    async waitForPaymentId (orderUuid, type) {
      try {
        const payload = {
          paymentType: type,
          orderUuid: orderUuid,
          numberType: this.numberType,
          durationUnit: this.durationType
        }

        const response = await this.$store.dispatch('buyNewNumber', payload)

        return response
      } catch (e) {
        if (e.status === 'waiting') {
          await delay(10000)
          return this.waitForPaymentId(orderUuid, type)
        } else {
          return false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-family {
  font-family: 'SF Pro Display' !important;
}

.main {
  width: 60%;

  &__content__left {
    flex: 70%;

    &>div:first-child {
      box-shadow: 0px 1px 1px 0 #00000010;
      border: 0.8px solid #E9ECEF;
    }
  }

  &__content__right {
    flex: 30%;
    position: relative;
    box-shadow: 0px 1px 1px 0 #00000010;
    border: 0.8px solid #E9ECEF;
    font-family: 'SF Pro Display' !important;
  }
}
</style>
